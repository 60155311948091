@tailwind base;
@tailwind components;
@tailwind utilities;

body * {
  font-family: 'DM Sans';
}

@layer components {
  .btn {
    @apply font-semibold cursor-pointer font-mont;
  }
  .btn-primary {
    @apply bg-primary-700 text-white text-[20px] leading-[28px] dark:bg-primary-600 dark:text-white rounded-[14px];
    @apply hover:bg-primary-800 dark:hover:bg-primary-500;
    @apply active:bg-primary-800 dark:active:bg-primary-500;
    @apply disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-primary-700 dark:disabled:hover:bg-primary-600;
  }
  .btn-secondary {
    @apply bg-primary-700 text-white text-[16px] leading-[24px] dark:bg-white dark:text-black rounded-[12px];
    @apply hover:bg-primary-800 dark:hover:bg-neutrals-200;
    @apply active:bg-primary-800 dark:active:bg-neutrals-200;
    @apply disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-primary-700 dark:disabled:hover:bg-white;
  }

  .btn-link {
    @apply bg-transparent text-black text-[16px] leading-[24px] dark:text-white rounded-[12px];
  }
}
